import { PaymentIntent } from "@stripe/stripe-js/types/api/payment-intents";
import { notEmpty } from "@uplift-ltd/ts-helpers";
import { useMemo } from "react";
import { ProductTypesEnum } from "__generated__/globalTypes";
import {
  CartInfoNode as Product,
  CartInfoNodes as CartItem,
  CartQueryCart as Cart,
} from "components/Cart/__generated__/queries";
import { splitCoursesAndBundles } from "components/courses/MyCourses/util";
import {
  GTM_ENGINE_IS_RED_CONVERSION_EVENT_ADD_TO_CART,
  GTM_ENGINE_IS_RED_CONVERSION_EVENT_PURCHASE,
  KLAVIYO_PUBLIC_API_KEY,
  META_ENGINE_IS_RED_PIXEL_ID,
} from "constants/site";

export type StripeStatus = PaymentIntent.Status | false | null;

export const getStripeClientSecret = (): string | null => {
  return new URLSearchParams(window.location.search).get("payment_intent_client_secret");
};

export const triggerAddToCartConversionTrackers = () => {
  // Google
  if (GTM_ENGINE_IS_RED_CONVERSION_EVENT_ADD_TO_CART) {
    window.gtag("event", "conversion", {
      send_to: GTM_ENGINE_IS_RED_CONVERSION_EVENT_ADD_TO_CART,
    });
  }

  // Meta
  if (META_ENGINE_IS_RED_PIXEL_ID) {
    window.fbq("track", "AddToCart");
  }
};

export const triggerPurchaseConversionTrackers = (
  amount: number,
  currency: string,
  transactionId: string | number,
  productIds: number[]
) => {
  // Google
  if (GTM_ENGINE_IS_RED_CONVERSION_EVENT_PURCHASE) {
    window.gtag("event", "conversion", {
      send_to: GTM_ENGINE_IS_RED_CONVERSION_EVENT_PURCHASE,
      value: amount,
      currency: currency.toUpperCase(),
      transaction_id: transactionId,
    });
  }

  // Meta
  if (META_ENGINE_IS_RED_PIXEL_ID) {
    window.fbq("track", "Purchase", { value: amount, currency: currency.toUpperCase() });
  }

  // Klaviyo
  if (KLAVIYO_PUBLIC_API_KEY && window.klaviyo) {
    window.klaviyo.push([
      "track",
      "Purchase",
      {
        ProductIds: productIds,
        Value: amount,
        Currency: currency.toUpperCase(),
        TransactionId: transactionId,
      },
    ]);
  }
};

export const useIsProductBundled = (items: CartItem[]) => {
  const { individualCourses } = useMemo(() => {
    return splitCoursesAndBundles(items.map(n => n?.product?.node).filter(notEmpty));
  }, [items]);

  return (product: Product) =>
    product.type === ProductTypesEnum.SIMPLE && !individualCourses.includes(product);
};

export const useProductIds = (cart: Cart | null | undefined) => {
  return useMemo(
    () =>
      cart?.contents?.nodes
        ?.map(node => parseInt(node?.product?.node?.id || "", 10))
        .filter(notEmpty) || [],
    [cart]
  );
};
