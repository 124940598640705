import cx from "classnames";
import React from "react";
import { useUID } from "react-uid";
import { HelpTextProps } from "./HelpText";
import styles from "./Form.module.css";

interface FormGroupBaseProps {
  className?: string;
  label: React.ReactElement<{ className?: string }>;
  input: React.ReactNode;
  helpText?: React.ReactNode;
}

export function FormGroup({ className, label, input, helpText }: FormGroupBaseProps) {
  return (
    <div className={cx(styles.formGroup, className)}>
      {React.cloneElement(label, { className: cx(label.props.className, "mb-1") })}
      {input}
      {helpText}
    </div>
  );
}

interface UseFormGroupOptions {
  id?: string;
}

interface UseFormGroupResult {
  labelProps: Pick<JSX.IntrinsicElements["label"], "htmlFor">;
  inputProps: Pick<JSX.IntrinsicElements["input"], "id" | "aria-describedby">;
  helpTextProps: Pick<HelpTextProps, "id">;
}

export function useFormGroup({ id }: UseFormGroupOptions): UseFormGroupResult {
  const randomId = useUID();
  const inputId = id || randomId;
  const descriptionId = `${inputId}-description`;
  return {
    labelProps: { htmlFor: inputId },
    inputProps: { id: inputId, "aria-describedby": descriptionId },
    helpTextProps: { id: descriptionId },
  };
}
