import { makeUrl } from "@uplift-ltd/strings";
import cx from "classnames";
import React from "react";
import { CourseProgressEnum } from "__generated__/globalTypes";
import Button, { ButtonLink } from "components/common/Button";
import CourseBadge from "components/common/CourseBadge";
import { COURSE_URL, EVENT_URL } from "constants/urls";
import { EventState, getEnrollmentDetailsForCourse } from "helpers/courseDetails";
import CourseInfo from "./CourseInfo";
import { MyCoursesQueryCourseInlineFragment as EnrolledCourse } from "./__generated__/MyCourses";
import { getCourseProgressLabel } from "./util";
import styles from "./Course.module.css";

interface CourseProps {
  course: EnrolledCourse;
  isComponentCourse?: boolean;
}

const Course = ({ course, isComponentCourse = false }: CourseProps) => {
  const isComplete = course.courseProgress === CourseProgressEnum.COMPLETED;

  const courseUrl = makeUrl(COURSE_URL, {
    slug: course.slug as string,
    page: (isComplete ? CourseProgressEnum.COMPLETED : course.courseProgress).toLowerCase(),
  });

  const eventUrl = course.event?.slug
    ? makeUrl(EVENT_URL, {
        slug: course.event.slug,
      })
    : null;

  const { buttonText, messageText, eventState, posttestPercentage } =
    getEnrollmentDetailsForCourse(course);

  const size = isComponentCourse ? "sm" : "md";

  return (
    <div className={cx(styles.course, { [styles.componentCourse]: isComponentCourse })}>
      <div className={styles.courseContent}>
        <h2 className={styles.courseTitle}>{course.title}</h2>
        {messageText && <div className={styles.courseSubtitle}>{messageText}</div>}
        {posttestPercentage ? (
          <p className={styles.courseSubtitle}>Your Posttest score was: {posttestPercentage}%</p>
        ) : null}
        <div className={styles.buttonContainer}>
          {!eventState && (
            <ButtonLink theme={isComplete ? "secondary" : "primary"} href={courseUrl} size={size}>
              {buttonText}
            </ButtonLink>
          )}
          {eventState === EventState.AWAITING_COMPLETION && eventUrl && (
            <ButtonLink theme="secondary" href={eventUrl} size={size}>
              {buttonText}
            </ButtonLink>
          )}
          {eventState === EventState.AWAITING_READINESS && (
            <Button theme="primary" disabled size={size}>
              {buttonText}
            </Button>
          )}
          <CourseInfo course={course} />
        </div>
      </div>
      <div className={styles.courseBadgeContainer}>
        <CourseBadge size="sm" type={getCourseProgressLabel(course)} />
      </div>
    </div>
  );
};

export default Course;
