import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React, { useCallback } from "react";
import useRouterNavigation from "hooks/useRouterNavigation";
import Link, { LinkProps } from "../Link";
import styles from "./Button.module.css";

type ButtonTheme =
  | "primary"
  | "secondary"
  | "secondaryOutline"
  | "light"
  | "textButton"
  | "danger"
  | "dangerInverse";
type ButtonSize = "sm" | "md";

const DEFAULT_THEME = "primary";
const DEFAULT_SIZE = "md";

type ButtonBaseProps = {
  theme?: ButtonTheme;
  size?: ButtonSize;
  icon?: React.ReactNode;
};

export type ButtonProps = Omit<JSX.IntrinsicElements["button"], "ref"> &
  ButtonBaseProps & {
    children?: React.ReactNode;
  };

const WrappedButton = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    className,
    children,
    icon,
    theme = DEFAULT_THEME,
    size = DEFAULT_SIZE,
    type = "button",
    onClick,
    ...rest
  },
  ref
) {
  return (
    <button
      ref={ref}
      className={cx(
        styles.root,
        styles[theme],
        styles[size],
        { [styles.hasIcon]: icon },
        className
      )}
      type={type}
      onClick={onClick}
      {...rest}
    >
      {icon}
      {children}
    </button>
  );
});

export type ButtonLinkProps = LinkProps & ButtonBaseProps;

const WrappedButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(function ButtonLink(
  { className, children, icon, theme = DEFAULT_THEME, size = DEFAULT_SIZE, ...rest },
  ref
) {
  return (
    <Link
      ref={ref}
      className={cx(
        styles.root,
        styles[theme],
        styles[size],
        { [styles.hasIcon]: icon },
        className
      )}
      {...rest}
    >
      {icon} {children}
    </Link>
  );
});

export type BackButtonProps = {
  backToHref?: LinkProps["href"];
  alwaysShow?: boolean;
};

const WrappedBackButton = React.forwardRef<HTMLButtonElement, BackButtonProps & ButtonProps>(
  function Button(
    {
      children,
      className,
      theme = "textButton",
      size = "DEFAULT_SIZE",
      type = "button",
      backToHref,
      ...rest
    },
    ref
  ) {
    const { back, push } = useRouterNavigation();

    const handleClick = useCallback(() => {
      if (backToHref) {
        push(backToHref);
      } else {
        back();
      }
    }, [backToHref, back, push]);

    return (
      <button
        ref={ref}
        className={cx(styles.root, styles.backButton, styles[theme], styles[size], className)}
        type={type}
        onClick={handleClick}
        {...rest}
      >
        <FontAwesomeIcon icon={faArrowLeft} className={cx("text-2xl", !!children && "mr-4")} />
        {children}
      </button>
    );
  }
);

export default WrappedButton;

export { WrappedButtonLink as ButtonLink, WrappedBackButton as BackButton };
