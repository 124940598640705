import cx from "classnames";
import React from "react";
import styles from "./CourseBadge.module.css";

type CourseBadgeSize = "xs" | "sm" | "lg";
type CourseBadgeType =
  | "new"
  | "updated"
  | "info"
  | "discount"
  | "pending"
  | "progress"
  | "completed"
  | "ended";

const DEFAULT_SIZE = "lg";

interface CourseBadgeProps {
  size?: CourseBadgeSize;
  type: CourseBadgeType;
  label?: string | null;
  className?: string | null;
}

const BADGES = {
  new: "New!",
  updated: "Updated!",
  info: "More Info",
  discount: "Discount Package",
  pending: "Not Started",
  progress: "In Progress",
  completed: "Completed",
  ended: "Ended",
};

export default function CourseBadge({
  size = DEFAULT_SIZE,
  type,
  label,
  className,
}: CourseBadgeProps) {
  return (
    <span className={cx(styles.root, styles[size], styles[type], className)}>
      {BADGES[type]}
      {label && <> {label}</>}
    </span>
  );
}
