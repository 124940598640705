import cx from "classnames";
import he from "he";
import React from "react";
import Container from "components/common/Container";
import HtmlContent from "components/common/HtmlContent";
import { usePageMeta } from "hooks/usePageMeta";
import styles from "./SectionHeader.module.css";

type SectionHeaderColor = "default" | "white";

const DEFAULT_COLOR = "default";

interface SectionHeaderProps {
  color?: SectionHeaderColor;
  title?: string | null;
  summary?: string | null;
  id?: string | null;
}

interface SectionHeaderWithSeoProps extends SectionHeaderProps {
  seo?: {
    title?: string | null;
  } | null;
}

export const IndividualSectionHeader = ({
  color = DEFAULT_COLOR,
  title,
  summary,
  id,
}: SectionHeaderProps) => {
  return (
    <div className={cx(styles.sectionHeader, styles[color])}>
      <Container>
        {id && <div id={id} />}
        {title && <h1 className={styles.sectionHeaderHeading}>{he.decode(title)}</h1>}
        {summary && (
          <HtmlContent className={styles.sectionHeaderHeroContent}>{summary}</HtmlContent>
        )}
      </Container>
    </div>
  );
};

const SectionHeader = ({
  color = DEFAULT_COLOR,
  title,
  seo,
  summary,
  id,
}: SectionHeaderWithSeoProps) => {
  usePageMeta(title, seo);

  if (!title) return null;

  return <IndividualSectionHeader color={color} title={title} summary={summary} id={id} />;
};

export default SectionHeader;
