import cx from "classnames";
import React, { PropsWithChildren } from "react";

interface Props {
  size?: "small" | "large";
}

const Container = ({ children, size = "large" }: PropsWithChildren<Props>) => {
  return (
    <div
      className={cx("container mx-auto my-12 px-5", {
        "max-w-lxl": size === "large",
        "max-w-2xl": size === "small",
      })}
    >
      {children}
    </div>
  );
};

export default Container;
