import React from "react";
import { CourseProgressEnum } from "__generated__/globalTypes";
import { getExpiresText } from "helpers/courseDetails";
import {
  CoreCourseFieldsFragment as CoreCourseFields,
  MyCoursesQueryCourseGroupInlineFragment as EnrolledCourseGroup,
} from "./__generated__/MyCourses";
import styles from "./Course.module.css";

interface InfoProps {
  courseOrGroup: CoreCourseFields | (EnrolledCourseGroup & { courseProgress: CourseProgressEnum });
  programCode?: string | null;
  ceUnits?: number | null;
  isGroup?: boolean;
}

interface GroupInfoProps {
  group: EnrolledCourseGroup;
  progress: CourseProgressEnum;
}

interface CourseInfoProps {
  course: CoreCourseFields;
}

const Info = ({ courseOrGroup, programCode, ceUnits, isGroup = false }: InfoProps) => {
  const expires = getExpiresText(courseOrGroup, isGroup);
  const programCodeLabel = isGroup ? "Package Program Code" : "Program Code";
  const creditsLabel = isGroup ? "Total CE Credits" : "CE Credits";

  return (
    <div className={styles.courseDetails}>
      <div>
        <div>
          <span className={styles.courseDetailsName}>{programCodeLabel}:</span>{" "}
          <span className={styles.courseDetailsValue}>{programCode}</span>
        </div>
        <div>
          <span className={styles.courseDetailsName}>{creditsLabel}:</span>{" "}
          <span className={styles.courseDetailsValue}>{ceUnits}</span>
        </div>
      </div>
      {expires && <div>{expires}</div>}
    </div>
  );
};

export const GroupInfo = ({ group, progress }: GroupInfoProps) => {
  return (
    <Info
      courseOrGroup={{ ...group, courseProgress: progress }}
      ceUnits={group.ceUnits}
      programCode={group.courseGroupFields?.programCode}
      isGroup
    />
  );
};

const CourseInfo = ({ course }: CourseInfoProps) => {
  return (
    <Info
      courseOrGroup={course}
      ceUnits={course.ceUnits}
      programCode={course.courseFields?.programCode}
    />
  );
};

export default CourseInfo;
