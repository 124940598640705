import cx from "classnames";
import React from "react";
import Heading from "components/common/Heading";
import styles from "./Form.module.css";

interface FieldSetProps {
  className?: string;
  bodyClassName?: string;
  label: string;
}

export const FieldSet: React.FC<FieldSetProps> = ({
  children,
  className,
  bodyClassName,
  label,
}) => {
  return (
    <fieldset className={cx(styles.fieldSet, className)}>
      <Heading className={styles.fieldSetLabel} tag="h4">
        {label}
      </Heading>
      <div className={cx(styles.fieldSetBody, bodyClassName)}>{children}</div>
    </fieldset>
  );
};

interface FieldSetRowProps {
  className?: string;
}

export const FieldSetRow: React.FC<FieldSetRowProps> = ({ children, className }) => {
  return <div className={cx(styles.fieldSetRow, className)}>{children}</div>;
};
