import { ExclamationIcon, BadgeCheckIcon, InformationCircleIcon } from "@heroicons/react/solid";
import cx from "classnames";
import React from "react";
import styles from "./Alert.module.css";

export type AlertTheme = "success" | "danger" | "info";

interface AlertProps {
  className?: string;
  children: React.ReactNode;
  theme: AlertTheme;
}

export default function Alert({ children, className, theme }: AlertProps) {
  return (
    <div className={cx(styles.root, styles[theme], className)}>
      <div className={styles.body}>
        <div className={styles.iconWrap}>
          {theme === "danger" && <ExclamationIcon className={styles.icon} aria-hidden="true" />}
          {theme === "success" && <BadgeCheckIcon className={styles.icon} aria-hidden="true" />}
          {theme === "info" && <InformationCircleIcon className={styles.icon} aria-hidden="true" />}
        </div>
        <div className={styles.textWrap}>
          <div className={styles.text}>{children}</div>
        </div>
      </div>
    </div>
  );
}
