import { ProfileInfoUserFields } from "components/__generated__/Profile";

export const doesUserHaveLicenseData = (
  currentUser:
    | {
        userFields: Pick<ProfileInfoUserFields, "licenseData"> | null | undefined;
      }
    | null
    | undefined
) => {
  const licenseData = currentUser?.userFields?.licenseData?.[0];
  return (
    licenseData?.type &&
    ((licenseData.licenseNumber && licenseData.state) || licenseData?.type === "Not Licensed")
  );
};
