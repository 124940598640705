import { Dialog, Menu, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import cx from "classnames";
import React, { Fragment } from "react";
import { ButtonLink } from "components/common/Button";
import Link from "components/common/Link";
import Logo from "components/common/Logo";
import {
  HOME_URL,
  LOGIN_URL,
  LOGOUT_URL,
  MY_COURSES_URL,
  MY_WEBINARS_URL,
  ORDER_HISTORY_URL,
  PROFILE_URL,
} from "constants/urls";
import { mapNodes } from "helpers/mapNodes";
import { useAssertMainMenuItemsContext } from "hooks/useMenuItemsContext";
import { useUserContext } from "hooks/useUserContext";
import styles from "./Navbar.module.css";

interface NavbarProps {
  mobileMenuOpen: boolean;
  setMobileMenuOpen: (open: boolean) => void;
}

// MOBILE MENU
// TODO: move this into the header
const Navbar: React.FC<NavbarProps> = ({ mobileMenuOpen, setMobileMenuOpen }) => {
  const { currentUser } = useUserContext();
  const menuItems = useAssertMainMenuItemsContext();

  return (
    <Transition.Root show={mobileMenuOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className={styles.mobile}
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className={styles.mobileBody}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className={styles.mobileOverlay} />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className={styles.mobileContent}>
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className={styles.mobileClose}>
                  <button
                    type="button"
                    className={styles.mobileCloseButton}
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <XIcon className={styles.mobileCloseIcon} aria-hidden="true" />
                    <span className="sr-only">Close sidebar</span>
                  </button>
                </div>
              </Transition.Child>
              <div className={styles.mobileMenu}>
                <div className={styles.mobileLogoImage}>
                  <Link href={HOME_URL}>
                    <Logo />
                  </Link>
                </div>
                <nav className={styles.mobileItems}>
                  {menuItems.map(menuItem => (
                    <Menu key={menuItem.id} as="div" className={styles.mobileItem}>
                      {({ open }) => (
                        <div
                          className={cx(styles.mobileItemMenuWrapper, {
                            [styles.mobileItemMenuOpen]: open,
                          })}
                        >
                          <div className={styles.mobileItemWrapper}>
                            {menuItem.childItems?.edges && menuItem.childItems?.edges.length > 0 ? (
                              <Menu.Button className={styles.mobileItemMenu}>
                                {menuItem.label}
                                <ChevronDownIcon
                                  className="-mr-1 ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            ) : (
                              <Link href={menuItem.path || ""} className={styles.mobileItemNoMenu}>
                                {menuItem.label}
                              </Link>
                            )}
                          </div>
                          {menuItem.childItems?.edges && menuItem.childItems?.edges.length > 0 ? (
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items static className={styles.submenuItems}>
                                {mapNodes(menuItem.childItems, childMenuItem => (
                                  <Menu.Item key={childMenuItem.id}>
                                    {({ active }) => (
                                      <Link
                                        href={childMenuItem.path || ""}
                                        className={cx(styles.submenuItem, {
                                          [styles.menuItemActive]: active,
                                        })}
                                      >
                                        {childMenuItem.label}
                                      </Link>
                                    )}
                                  </Menu.Item>
                                ))}
                              </Menu.Items>
                            </Transition>
                          ) : null}
                        </div>
                      )}
                    </Menu>
                  ))}
                  <div className={cx(styles.mobileItemMenuWrapper, styles.mobileItem)}>
                    <Link
                      activeClassName={styles.mobileItemActive}
                      href="https://support.zurinstitute.com/"
                    >
                      Customer Support
                    </Link>
                  </div>
                  <div>
                    {currentUser && (
                      <Menu as="div" className={styles.mobileItem}>
                        {({ open }) => (
                          <div
                            className={cx(styles.mobileItemMenuWrapper, {
                              [styles.mobileItemMenuOpen]: open,
                            })}
                          >
                            <Menu.Button className={styles.mobileItemMenu}>
                              My Account
                              <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                            </Menu.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items static className={styles.submenuItems}>
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      href={PROFILE_URL}
                                      className={cx(styles.submenuItem, {
                                        [styles.menuItemActive]: active,
                                      })}
                                    >
                                      My Profile
                                    </Link>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      href={MY_COURSES_URL}
                                      className={cx(styles.submenuItem, {
                                        [styles.menuItemActive]: active,
                                      })}
                                    >
                                      My Courses
                                    </Link>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      href={MY_WEBINARS_URL}
                                      className={cx(styles.submenuItem, {
                                        [styles.menuItemActive]: active,
                                      })}
                                    >
                                      My Webinars
                                    </Link>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      href={ORDER_HISTORY_URL}
                                      className={cx(styles.submenuItem, {
                                        [styles.menuItemActive]: active,
                                      })}
                                    >
                                      Order History
                                    </Link>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  <Link href={LOGOUT_URL} className={cx(styles.submenuItem)}>
                                    Sign Out
                                  </Link>
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          </div>
                        )}
                      </Menu>
                    )}
                  </div>
                  <div className={cx(styles.mobileItemMenuWrapper, styles.mobileItem)}>
                    {!currentUser && <ButtonLink href={LOGIN_URL}>Login</ButtonLink>}
                  </div>
                </nav>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Navbar;
