import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ButtonLink } from "components/common/Button";
import Container from "components/common/Container";
import { NEWSLETTER_SIGNUP_URL } from "constants/site";
import styles from "./NewsletterSignup.module.css";

export const NewsletterSignup = () => (
  <div className={styles.mainWrapper}>
    <Container>
      <div className="text-2xl">
        Sign up for our free <strong>Clinical Updates</strong> newsletter
      </div>
      Subscribe today and be the first to know about new releases and promotions.
      <div className={styles.formContainer}>
        <ButtonLink href={NEWSLETTER_SIGNUP_URL} target="_blank" className={styles.submit}>
          Sign up <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
        </ButtonLink>
      </div>
    </Container>
  </div>
);
