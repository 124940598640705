import cx from "classnames";
import React from "react";
import PhoneInput, { PhoneInputProps } from "react-phone-number-input/input";
import { telToE164 } from "helpers/strings";
import { FieldStatus } from "./types";
import styles from "./Form.module.css";

export interface PhoneNumberInputProps extends Omit<PhoneInputProps, "value" | "onChange"> {
  status?: FieldStatus;
  value?: string;
  onChange?: PhoneInputProps["onChange"];
}

export function PhoneNumberInput({
  className,
  type = "text",
  status,
  value = "",
  onChange,
  ...rest
}: PhoneNumberInputProps) {
  return (
    <PhoneInput
      className={cx(styles.input, status && styles[`input-${status}`], className)}
      type={type}
      value={value && telToE164(value)}
      onChange={
        onChange ||
        (() => {
          // noop
        })
      }
      defaultCountry="US"
      {...rest}
    />
  );
}
