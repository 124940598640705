import cx from "classnames";
import React, { useState } from "react";
import { PageMeta } from "hooks/usePageMeta";
import LayoutFooter from "./LayoutFooter";
import LayoutHeader from "./LayoutHeader";
import Meta from "./Meta";
import Navbar from "./Navbar";
import WordpressBar from "./WordpressBar";
import styles from "./Layout.module.css";

interface LayoutProps {
  className?: string;
  initialMeta?: PageMeta | null;
}

const Layout: React.FC<LayoutProps> = ({ className, children, initialMeta }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className={styles.root}>
      <Meta initialMeta={initialMeta} />
      <Navbar mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
      <div className={styles.body}>
        <WordpressBar />
        <LayoutHeader setMobileMenuOpen={setMobileMenuOpen} />
        <div className={cx(styles.contentContainer, className)}>{children}</div>
        <LayoutFooter />
      </div>
    </div>
  );
};

export default Layout;
