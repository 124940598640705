import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gql, useEnhancedQuery } from "@uplift-ltd/apollo";
import { makeUrl } from "@uplift-ltd/strings";
import { useRouter } from "next/router";
import React from "react";
import { PageIdType } from "__generated__/globalTypes";
import { StaticPageParams } from "components/StaticPageTemplate";
import Link from "components/common/Link";
import { COURSE_URL_BASE, COURSES_URL } from "constants/urls";
import { mapNodes } from "helpers/mapNodes";
import { UserContext, useRouterQuery, useUserContext } from "hooks";
import {
  PageEditLinkQueryQuery as PageEditLinkQuery,
  PageEditLinkQueryVariables,
} from "./__generated__/index";
import styles from "./WordpressBar.module.css";

const PAGE_EDIT_LINK_QUERY = gql`
  query PageEditLinkQuery($id: ID!, $idType: PageIdType!) {
    page(id: $id, idType: $idType) {
      id
      adminEditUrl
    }
  }
`;

const isAdmin = (userContext: UserContext) => {
  const roleNames = mapNodes(userContext.currentUser?.roles, ({ name }) => name);
  return roleNames.includes("administrator");
};

const WordpressBar: React.FC = () => {
  const user = useUserContext();
  const userIsAdmin = user.isAuthenticated && isAdmin(user);

  const router = useRouter();
  const { routerQuery } = useRouterQuery<StaticPageParams>();
  const origPath = router.asPath;
  const fullPath = origPath.startsWith(COURSES_URL)
    ? makeUrl(COURSE_URL_BASE, { slug: routerQuery.slug })
    : origPath;

  const { data, loading } = useEnhancedQuery<PageEditLinkQuery, PageEditLinkQueryVariables>(
    PAGE_EDIT_LINK_QUERY,
    {
      variables: { id: fullPath, idType: PageIdType.URI },
      skip: !userIsAdmin,
    }
  );

  if (!userIsAdmin) {
    return null;
  }

  const url = data?.page?.adminEditUrl;
  const menuItem = { label: "Loading...", path: "" };

  if (!loading) {
    if (url) {
      menuItem.label = "Edit in Admin";
      menuItem.path = url;
    } else {
      menuItem.label = "Not editable in Admin";
    }
  }

  return (
    <div className={styles.wordpressBarContainer}>
      <nav className={styles.wordpressBar}>
        {menuItem.path ? (
          <Link
            className={styles.wordpressBarMenuItem}
            href={menuItem.path || ""}
            title={menuItem.label || ""}
          >
            <FontAwesomeIcon icon={faEdit} /> {menuItem.label}
          </Link>
        ) : (
          <span className={`${styles.wordpressBarMenuItem} ${styles.wordPresBarMenuItemDisabled}`}>
            {menuItem.label}
          </span>
        )}
      </nav>
    </div>
  );
};

export default WordpressBar;
