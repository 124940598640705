/* eslint-disable jsx-a11y/label-has-associated-control */

import { useEnhancedField } from "@uplift-ltd/formik";
import React, { useCallback } from "react";
import { FormGroup, useFormGroup } from "./FormGroup";
import { HelpText } from "./HelpText";
import { Input, InputProps } from "./Input";
import { Label } from "./Label";
import { PhoneNumberInput, PhoneNumberInputProps } from "./PhoneNumberInput";
import { Select, SelectProps } from "./Select";

interface InputGroupProps extends InputProps {
  className?: string;
  label: React.ReactNode;
  helpText?: React.ReactNode;
  onBeforeChange?(e: React.ChangeEvent<HTMLInputElement>): void;
}

export function InputGroup({ className, label, helpText, id, status, ...rest }: InputGroupProps) {
  const { labelProps, inputProps, helpTextProps } = useFormGroup({ id });
  return (
    <FormGroup
      className={className}
      label={
        <Label {...labelProps} required={rest.required}>
          {label}
        </Label>
      }
      input={<Input {...rest} {...inputProps} status={status} />}
      helpText={
        <HelpText {...helpTextProps} status={status}>
          {helpText}
        </HelpText>
      }
    />
  );
}

interface InputGroupFieldProps extends InputGroupProps {
  name: string;
  inputClassName?: string;
  helpTextClassName?: string;
}

export function InputGroupField({
  className,
  name,
  label,
  helpText,
  id,
  inputClassName,
  helpTextClassName,
  onBeforeChange,
  ...rest
}: InputGroupFieldProps) {
  const [field, meta] = useEnhancedField<string>({ name });
  const status = meta.touched && meta.error ? "danger" : undefined;
  const { labelProps, inputProps, helpTextProps } = useFormGroup({ id });

  const { onChange } = field;

  const combinedOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onBeforeChange?.(e);
      onChange(e);
    },
    [onBeforeChange, onChange]
  );

  return (
    <FormGroup
      className={className}
      label={
        <Label {...labelProps} required={rest.required}>
          {label}
        </Label>
      }
      input={
        <Input
          className={inputClassName}
          {...field}
          {...rest}
          {...inputProps}
          onChange={combinedOnChange}
          status={status}
        />
      }
      helpText={
        <HelpText className={helpTextClassName} {...helpTextProps} status={status}>
          {(status === "danger" && meta.error) || helpText}
        </HelpText>
      }
    />
  );
}

export interface SelectGroupProps<OptionValue extends string = string, OptionExtra = never>
  extends SelectProps<OptionValue, OptionExtra> {
  className?: string;
  label: React.ReactNode;
  helpText?: React.ReactNode;
}

export function SelectGroup<OptionValue extends string = string, OptionExtra = never>({
  className,
  id,
  label,
  helpText,
  status,
  ...rest
}: SelectGroupProps<OptionValue, OptionExtra>) {
  const { labelProps, inputProps, helpTextProps } = useFormGroup({ id });
  return (
    <FormGroup
      className={className}
      label={
        <Label {...labelProps} required={rest.required}>
          {label}
        </Label>
      }
      input={<Select {...rest} {...inputProps} status={status} />}
      helpText={
        <HelpText {...helpTextProps} status={status}>
          {helpText}
        </HelpText>
      }
    />
  );
}

// TODO: make onChange optional, allow override
export interface SelectGroupFieldProps extends Omit<SelectGroupProps, "onChange"> {
  name: string;
}

export function SelectGroupField({ name, helpText, ...rest }: SelectGroupFieldProps) {
  const [field, meta] = useEnhancedField<string>({ name });
  const status = meta.touched && meta.error ? "danger" : undefined;
  return (
    <SelectGroup
      {...rest}
      {...field}
      status={status}
      helpText={(status === "danger" && meta.error) || helpText}
    />
  );
}

interface PhoneNumberInputGroupProps extends PhoneNumberInputProps {
  className?: string;
  label: React.ReactNode;
  helpText?: React.ReactNode;
}

export function PhoneNumberInputGroup({
  className,
  label,
  helpText,
  id,
  status,
  ...rest
}: PhoneNumberInputGroupProps) {
  const { labelProps, inputProps, helpTextProps } = useFormGroup({ id });
  return (
    <FormGroup
      className={className}
      label={
        <Label {...labelProps} required={rest.required}>
          {label}
        </Label>
      }
      input={<PhoneNumberInput {...rest} {...inputProps} status={status} />}
      helpText={
        <HelpText {...helpTextProps} status={status}>
          {helpText}
        </HelpText>
      }
    />
  );
}

interface PhoneNumberInputGroupFieldProps extends PhoneNumberInputGroupProps {
  name: string;
  inputClassName?: string;
  helpTextClassName?: string;
}

export function PhoneNumberInputGroupField({
  className,
  name,
  label,
  helpText,
  id,
  inputClassName,
  helpTextClassName,
  ...rest
}: PhoneNumberInputGroupFieldProps) {
  const [field, meta] = useEnhancedField<string>({ name });
  const status = meta.touched && meta.error ? "danger" : undefined;
  const { labelProps, inputProps, helpTextProps } = useFormGroup({ id });
  return (
    <FormGroup
      className={className}
      label={
        <Label {...labelProps} required={rest.required}>
          {label}
        </Label>
      }
      input={
        <PhoneNumberInput
          className={inputClassName}
          {...field}
          {...rest}
          {...inputProps}
          status={status}
          onChange={value => {
            field.onChange({ target: { name, value } });
          }}
        />
      }
      helpText={
        <HelpText className={helpTextClassName} {...helpTextProps} status={status}>
          {(status === "danger" && meta.error) || helpText}
        </HelpText>
      }
    />
  );
}
