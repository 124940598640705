import cx from "classnames";
import React from "react";
import styles from "./HtmlContent.module.css";

type HtmlContentProps = Omit<JSX.IntrinsicElements["div"], "children"> & {
  children?: string | null;
};

const HtmlContent = ({ children, className, ...props }: HtmlContentProps) => {
  const content = children || "";

  return (
    <div
      className={cx(styles.htmlContent, className)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: content }}
      {...props}
    />
  );
};

export default HtmlContent;
