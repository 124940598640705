import { useEnhancedFormikContext } from "@uplift-ltd/formik";
import cx from "classnames";
import React from "react";
import Button, { ButtonProps } from "../Button";

export function SubmitButton({ disabled, theme = "primary", className, ...rest }: ButtonProps) {
  const { isSubmitting } = useEnhancedFormikContext();
  return (
    <Button
      className={cx("mx-auto", className)}
      disabled={disabled || isSubmitting}
      theme={theme}
      type="submit"
      {...rest}
    />
  );
}
