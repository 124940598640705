import React, { PropsWithChildren } from "react";
import { PlacesType, Tooltip as ReactTooltip } from "react-tooltip";
import styles from "./Tooltip.module.css";

interface Props {
  text: string;
  id: string;
  placement?: PlacesType;
}

export const Tooltip = ({ text, id, placement, children }: PropsWithChildren<Props>) => {
  return (
    <span>
      <ReactTooltip id={id} />
      <span
        data-tooltip-id={id}
        data-tooltip-content={text}
        data-tooltip-place={placement || "top"}
        className={styles.tooltipTrigger}
      >
        {children}
      </span>
    </span>
  );
};
