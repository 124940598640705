import { Menu, Transition } from "@headlessui/react";
import { DatabaseIcon, MenuAlt2Icon, ShoppingCartIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { useEnhancedQuery } from "@uplift-ltd/apollo";
import { makeUrl } from "@uplift-ltd/strings";
import { notEmpty } from "@uplift-ltd/ts-helpers";
import { useRouter } from "next/router";
import React, { Fragment, useMemo } from "react";
import { CART_QUERY, CartQuery } from "components/Cart/queries";
import Button, { ButtonLink } from "components/common/Button";
import Link from "components/common/Link";
import Logo from "components/common/Logo";
import NewsletterPopup from "components/common/NewsletterPopup/NewsletterPopup";
import { splitCoursesAndBundles } from "components/courses/MyCourses/util";
import Carousel from "components/events/Carousel";
import {
  CART_URL,
  CE_REQUIREMENTS_URL,
  EVENTS_URL,
  HOME_URL,
  LOGIN_URL,
  LOGOUT_URL,
  MY_COURSES_URL,
  MY_WEBINARS_URL,
  ORDER_HISTORY_URL,
  PROFILE_URL,
  SEARCH_PAGE_URL,
} from "constants/urls";
import { useRouterQuery } from "hooks/useRouterQuery";
import { useUserContext } from "hooks/useUserContext";
import ExploreMenu from "./ExploreMenu";
import HorizontalMenu from "./HorizontalMenu";
import PromoBanner, { DISCOUNT_BANNER_QUERY } from "./PromoBanner";
import SearchBar from "./SearchBar";
import { GetDiscountBannerQueryQuery as GetDiscountBannerQuery } from "./__generated__/PromoBanner";
import styles from "./LayoutHeader.module.css";

interface HeaderProps {
  setMobileMenuOpen: (open: boolean) => void;
}

const LayoutHeader: React.FC<HeaderProps> = ({ setMobileMenuOpen }) => {
  const router = useRouter();
  const { routerQuery } = useRouterQuery<{ nextPage?: string }>();
  const { currentUser } = useUserContext();
  const { data: cartData } = useEnhancedQuery<CartQuery>(CART_QUERY);
  const { data: bannerData } = useEnhancedQuery<GetDiscountBannerQuery>(DISCOUNT_BANNER_QUERY);

  const hideEventsCarousel = router.pathname === HOME_URL || router.pathname.startsWith(EVENTS_URL);

  const numCartItems = useMemo(() => {
    const { bundles, individualCourses } = splitCoursesAndBundles(
      cartData?.cart?.contents?.nodes?.map(n => n?.product?.node).filter(notEmpty) || []
    );

    return bundles.length + individualCourses.length;
  }, [cartData?.cart?.contents?.nodes]);

  return (
    <header className={styles.root}>
      {bannerData?.discountBanner?.discountBannerOptions?.displayBanner && (
        <PromoBanner options={bannerData?.discountBanner?.discountBannerOptions} />
      )}
      <NewsletterPopup />
      <div className={styles.logoRow}>
        <div className={styles.headerContainer}>
          <div className={styles.logoRowContent}>
            <div className={styles.logoRowLeft}>
              <div className={styles.logoRowImage}>
                <Link href={HOME_URL}>
                  <Logo />
                </Link>
              </div>
              <ExploreMenu />
            </div>
            <ul className={styles.logoRowMenu}>
              {router.pathname !== SEARCH_PAGE_URL && <SearchBar />}
              <li className="md:block hidden">
                <Link href={CE_REQUIREMENTS_URL}>Check CE Requirements</Link>
              </li>
              <li className="md:block hidden">
                <Link href="https://support.zurinstitute.com/">Support</Link>
              </li>
              {currentUser ? (
                <li className="md:block hidden">
                  <Menu as="div" className="relative inline-block text-left">
                    {({ open }) => (
                      <>
                        <Menu.Button className={styles.myProfile}>
                          My Account
                          <ChevronDownIcon className="w-4 h-4" aria-hidden="true" />
                        </Menu.Button>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items static className={styles.menuItems}>
                            <div className={styles.myProfileMenuTokens}>
                              <DatabaseIcon className="inline-flex w-4 h-4" /> {currentUser.credits}{" "}
                              Tokens
                            </div>
                            <Menu.Item>
                              <Link href={PROFILE_URL} className={styles.myProfileMenuItem}>
                                My Profile
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <Link href={MY_COURSES_URL} className={styles.myProfileMenuItem}>
                                My Courses
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <Link href={MY_WEBINARS_URL} className={styles.myProfileMenuItem}>
                                My Webinars
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <Link href={ORDER_HISTORY_URL} className={styles.myProfileMenuItem}>
                                Order History
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <Link href={LOGOUT_URL} className={styles.myProfileMenuItem}>
                                Sign Out
                              </Link>
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </li>
              ) : (
                <li className="md:block hidden">
                  <ButtonLink
                    href={
                      routerQuery.nextPage
                        ? makeUrl(LOGIN_URL, null, { nextPage: routerQuery.nextPage })
                        : LOGIN_URL
                    }
                  >
                    Login
                  </ButtonLink>
                </li>
              )}
              {numCartItems > 0 && (
                <div className="mx-2 lg:mx-4">
                  <Link href={CART_URL} className={styles.cartIconWrapper}>
                    <ShoppingCartIcon className={styles.cartIcon} />({numCartItems})
                  </Link>
                </div>
              )}
              <div className={styles.mobileToggleWrapper}>
                <Button
                  theme="textButton"
                  className={styles.mobileToggle}
                  onClick={() => setMobileMenuOpen(true)}
                  type="button"
                >
                  <span className="sr-only">Open menu</span>
                  <MenuAlt2Icon className={styles.mobileToggleIcon} aria-hidden="true" />
                </Button>
              </div>
            </ul>
          </div>
        </div>
      </div>
      {!hideEventsCarousel && <Carousel />}
      <HorizontalMenu />
    </header>
  );
};

export default LayoutHeader;
