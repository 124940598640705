import { notEmpty } from "@uplift-ltd/ts-helpers";
import { parse, parseISO } from "date-fns";
import { formatInTimeZone, toDate } from "date-fns-tz";
import {
  EventResourcesFragmentFragment,
  EventResourcesFragmentResources,
} from "components/events/__generated__/queries";
import { EventTimestamps } from "components/events/queries";
import { SECONDARY_TIMEZONE, WORDPRESS_TIMEZONE } from "constants/date";

// @see https://date-fns.org/v2.28.0/docs/format
enum DATE_FORMAT {
  Day = "EEEE, d MMMM yyyy",
  IsoDate = "yyyy-MM-dd",
  Time = "h:mm aaa",
}

export type DateInput = string | null | undefined;
export type FormattedDate = string | null;

const formatDate = (
  dateString: DateInput,
  format: DATE_FORMAT,
  timezone: string,
  new12HourTime?: string
): FormattedDate => {
  if (!dateString) return null;
  const formatter = (date: Date) => formatInTimeZone(date, timezone, format);

  const startDate = toDate(dateString, { timeZone: WORDPRESS_TIMEZONE });

  if (new12HourTime) {
    const endTimeComponents = parse(new12HourTime, DATE_FORMAT.Time, new Date());

    const endTimeHours = endTimeComponents.getHours();
    // ensure that minutes follow `mm` format
    const endTimeMinutes = endTimeComponents.getMinutes().toString().padStart(2, "0");

    const startDateIsoStr = formatInTimeZone(startDate, WORDPRESS_TIMEZONE, DATE_FORMAT.IsoDate);
    const newDateStr = `${startDateIsoStr} ${endTimeHours}:${endTimeMinutes}`;
    const endDate = toDate(newDateStr, { timeZone: WORDPRESS_TIMEZONE });

    return formatter(endDate);
  }

  return formatter(startDate);
};

const makeFormatter =
  (
    timezone: Parameters<typeof formatDate>[2],
    formatString: Parameters<typeof formatDate>[1] = DATE_FORMAT.Day
  ) =>
  (
    dateString: Parameters<typeof formatDate>[0],
    new12HourTime?: Parameters<typeof formatDate>[3]
  ) => {
    return formatDate(dateString, formatString, timezone, new12HourTime);
  };

export const formatStartDay = makeFormatter(WORDPRESS_TIMEZONE);
export const formatPacificTime = makeFormatter(WORDPRESS_TIMEZONE, DATE_FORMAT.Time);
export const formatEasternTime = makeFormatter(SECONDARY_TIMEZONE, DATE_FORMAT.Time);

export const isTimePast = (dateString: string): boolean => {
  const date = toDate(dateString, { timeZone: WORDPRESS_TIMEZONE }).getTime();
  const now = new Date().getTime();

  return now > date;
};

export const hasEventEnded = (event: { timestamps: Omit<EventTimestamps, "start"> }): boolean => {
  const now = Date.now() / 1000;
  const eventEndTime = event.timestamps.end;

  return now > eventEndTime;
};

export const formatShortDate = (dateString?: string | null, month: "long" | "short" = "long") => {
  if (!dateString) {
    return null;
  }

  const date = parseISO(dateString);
  return date.toLocaleString("en-US", { month, day: "numeric" });
};

interface EventWithResources {
  eventFields?: EventResourcesFragmentFragment | null;
}

export const getResources = (event: EventWithResources): EventResourcesFragmentResources[] => {
  const resources = event.eventFields?.resources?.filter(notEmpty) || [];

  event.eventFields?.bundledWith?.filter(notEmpty).forEach(bundledEvent => {
    resources.push(...(bundledEvent.eventFields?.resources?.filter(notEmpty) || []));
  });

  return resources.filter(resource => resource.uri && resource.title);
};
