import { gql } from "@uplift-ltd/apollo";
import React from "react";
import Link from "components/common/Link";
import { NEWSLETTER_SIGNUP_URL } from "constants/site";
import { GetDiscountBannerQueryDiscountBannerOptions } from "./__generated__/PromoBanner";
import styles from "./PromoBanner.module.css";

export const DISCOUNT_BANNER_QUERY = gql`
  query GetDiscountBannerQuery {
    discountBanner {
      discountBannerOptions {
        link
        linkText
        text
        displayBanner
      }
      pageSlug
      pageTitle
    }
  }
`;

interface PromoBannerProps {
  options: GetDiscountBannerQueryDiscountBannerOptions;
}

const PromoBanner = ({ options }: PromoBannerProps) => {
  return (
    <div className={styles.promoBanner}>
      <strong>{options.text}</strong>{" "}
      <Link href={options.link || NEWSLETTER_SIGNUP_URL} target="_blank">
        {options.linkText || "Click Here"}
      </Link>
    </div>
  );
};

export default PromoBanner;
