import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeUrl } from "@uplift-ltd/strings";
import { useInterval } from "@uplift-ltd/use-interval";
import React, { useCallback, useState } from "react";
import Button from "components/common/Button";
import CourseBadge from "components/common/CourseBadge";
import Link from "components/common/Link";
import { EVENT_URL } from "constants/urls";
import { mapNodes } from "helpers/mapNodes";
import { useEventsList } from "./queries";
import styles from "./Carousel.module.css";

const DEFAULT_ROTATION_INTERVAL = 5000;
const ROTATION_MARGIN = 200;

export type EventsCarouselProps = {
  rotationIntervalMs?: number;
};

interface CurrentEvent {
  index: number;
  lastChangedMs: number;
}

const setNewEventIndex = (index: number): CurrentEvent => {
  return {
    index,
    lastChangedMs: Date.now(),
  };
};

const EventsCarousel = ({
  rotationIntervalMs = DEFAULT_ROTATION_INTERVAL,
}: EventsCarouselProps) => {
  const [currentEvent, setCurrentEvent] = useState<CurrentEvent>(setNewEventIndex(0));
  const { data } = useEventsList();

  const events = mapNodes(data?.events);
  const hasMultiple = events.length > 1;

  const setNextEventIdx = useCallback(() => {
    if (!events.length) {
      setCurrentEvent(setNewEventIndex(0));
    } else if (Date.now() - currentEvent.lastChangedMs >= rotationIntervalMs - ROTATION_MARGIN) {
      setCurrentEvent(prev => setNewEventIndex((prev.index + 1) % events.length));
    }
  }, [events, currentEvent.lastChangedMs, rotationIntervalMs]);

  useInterval(setNextEventIdx, hasMultiple ? rotationIntervalMs : null);

  const eventToDisplay = events[currentEvent.index];

  const handleBack = () => {
    setCurrentEvent(prev => setNewEventIndex((events.length + prev.index - 1) % events.length));
  };

  const handleNext = () => {
    setCurrentEvent(prev => setNewEventIndex((prev.index + 1) % events.length));
  };

  if (!events.length) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.carouselWrapper}>
        {eventToDisplay?.slug && (
          <Link href={makeUrl(EVENT_URL, { slug: eventToDisplay.slug })}>
            <div className={styles.content}>
              <span className={styles.title}>
                {eventToDisplay?.eventFields?.eventShortTitle || eventToDisplay?.title}
              </span>{" "}
              <span className={styles.text}>/ {eventToDisplay?.eventFields?.eventExcerpt}</span>
              <CourseBadge size="sm" type="info" className={styles.badge} />
            </div>
          </Link>
        )}
        {hasMultiple ? (
          <div className={styles.controls}>
            <Button theme="textButton" onClick={() => handleBack()}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <Button theme="textButton" onClick={() => handleNext()}>
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EventsCarousel;
