import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import cx from "classnames";
import React, { Fragment } from "react";
import {
  BOARD_APPROVAL_URL,
  BUY_TOKENS_URL,
  COURSE_CATALOG_BY_AUTHOR,
  COURSE_CATALOG_BY_NUMBER_OF_CE,
  COURSE_CATALOG_BY_STATE,
  COURSE_CATALOG_BY_SUBJECT,
  COURSE_CATALOG_BY_TITLE,
  EVENTS_URL,
  PRE_LICENSING_COURSES_URL,
  PRIVATE_PRACTICE_TOOLS_ROOT_URL,
  SAVING_PACKAGES_URL,
  SUPERVISION_PACKAGES_URL,
} from "constants/urls";
import { useCertificatePrograms } from "hooks/useCertificatePrograms";
import Link from "../../Link";
import styles from "./HorizontalMenu.module.css";
import headerStyles from "./LayoutHeader.module.css";

const HorizontalMenu: React.FC = () => {
  const certificatePrograms = useCertificatePrograms();

  const menuItems = [
    {
      label: "Course Catalog",
      path: "#",
      children: [
        { label: "View by Subject", path: COURSE_CATALOG_BY_SUBJECT },
        { label: "View by Title", path: COURSE_CATALOG_BY_TITLE },
        { label: "View by Author", path: COURSE_CATALOG_BY_AUTHOR },
        { label: "View by State", path: COURSE_CATALOG_BY_STATE },
        { label: "View by Number of CEs", path: COURSE_CATALOG_BY_NUMBER_OF_CE },
        { label: "Pre-Licensing Courses", path: PRE_LICENSING_COURSES_URL },
        { label: "Savings Packages", path: SAVING_PACKAGES_URL },
        { label: "Supervision Packages", path: SUPERVISION_PACKAGES_URL },
        { label: "Private Practice Forms", path: PRIVATE_PRACTICE_TOOLS_ROOT_URL },
        { label: "Board Approvals", path: BOARD_APPROVAL_URL },
      ],
    },
    { label: "Board Approvals", path: BOARD_APPROVAL_URL, canHide: true },
    {
      label: "Certificate Programs",
      path: "#",
      children: certificatePrograms.map(program => ({
        label: program.name,
        path: program.catalogPath,
      })),
    },
    {
      label: "Savings Packages",
      path: "#",
      children: [
        { label: "Pre-Licensing Courses", path: PRE_LICENSING_COURSES_URL },
        { label: "Savings Packages", path: SAVING_PACKAGES_URL },
        { label: "Supervision Packages", path: SUPERVISION_PACKAGES_URL },
        { label: "Buy Tokens", path: BUY_TOKENS_URL },
      ],
    },
    { label: "Webinars/Trainings", path: EVENTS_URL },
  ];

  return (
    <div className={styles.horizontalMenuContainer}>
      <div className={headerStyles.headerContainer}>
        <nav className={styles.horizontalMenu}>
          {menuItems.map((menuItem, i) => (
            <Fragment key={`${menuItem.path}-${i}-fragment`}>
              <Menu
                as="div"
                className={cx(styles.horizontalMenuItemContainer, {
                  [styles.horizontalMenuItemContainerCanHide]: menuItem.canHide,
                })}
              >
                {({ open }) => (
                  <>
                    <Menu.Button className={headerStyles.horizontalMenu}>
                      <Link
                        className={styles.horizontalMenuItem}
                        href={menuItem.path || ""}
                        title={menuItem.label || ""}
                      >
                        {menuItem.label}
                      </Link>
                      {menuItem.children?.length ? (
                        <ChevronDownIcon className="w-4 h-4" aria-hidden="true" />
                      ) : null}
                    </Menu.Button>
                    {menuItem.children?.length ? (
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items static className={headerStyles.horizontalMenuItems}>
                          {menuItem.children?.map((item, index) => (
                            <Menu.Item key={index}>
                              <Link href={item.path} className={headerStyles.myProfileMenuItem}>
                                {item.label}
                              </Link>
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    ) : null}
                  </>
                )}
              </Menu>
            </Fragment>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default HorizontalMenu;
