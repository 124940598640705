import { notEmpty } from "@uplift-ltd/ts-helpers";
import React, { useMemo } from "react";
import { CourseProgressEnum } from "__generated__/globalTypes";
import CourseBadge from "components/common/CourseBadge";
import DownloadCertificateButton from "components/common/DownloadCertificate/DownloadCertificateButton";
import Link from "components/common/Link";
import Course from "components/courses/MyCourses/Course";
import { getExpiredText } from "helpers/courseDetails";
import { getStepIndex } from "helpers/courseProgress";
import { GroupInfo } from "./CourseInfo";
import { MyCoursesQueryCourseGroupInlineFragment as EnrolledCourseGroup } from "./__generated__/MyCourses";
import styles from "./CourseGroup.module.css";

interface CourseGroupProps {
  group: EnrolledCourseGroup;
  progress: CourseProgressEnum;
}

const getProgressLabel = (progress: CourseProgressEnum) => {
  if (progress === CourseProgressEnum.COMPLETED) return "completed";
  if (progress === CourseProgressEnum.CONTENT) return "progress";

  return "pending";
};

const CourseGroup = ({ group, progress }: CourseGroupProps) => {
  const courses = useMemo(() => {
    // If this is a webinar bundle, order by date
    if (group.courseGroupFields?.webinarTrainingsBundle) {
      return [...group.myCourses].sort((c1, c2) => {
        const index1 = c1.event?.timestamps.end || 0;
        const index2 = c2.event?.timestamps.end || 0;

        return index1 - index2;
      });
    }

    // Otherwise, order by progress
    return [...group.myCourses].sort((c1, c2) => {
      const index1 = getStepIndex(c1.courseProgress);
      const index2 = getStepIndex(c2.courseProgress);

      return index1 - index2;
    });
  }, [group.courseGroupFields?.webinarTrainingsBundle, group.myCourses]);
  const groupWithProgress = { ...group, courseProgress: progress };
  const expiredText = getExpiredText(groupWithProgress, true);
  const groupResources = group.courseGroupFields?.resources?.filter(notEmpty) || [];
  const webinarResources =
    group.courseGroupFields?.webinarTrainingsBundle?.eventFields?.resources?.filter(notEmpty) || [];
  const resources = [...groupResources, ...webinarResources];

  return (
    <div className={styles.bundle}>
      <div className={styles.bundleHeader}>
        <div className={styles.bundleTitle}>
          <h2>{group.title}</h2>
        </div>
        <div className={styles.bundleBadgeContainer}>
          <CourseBadge size="sm" type={getProgressLabel(progress)} />
        </div>
      </div>
      <div className={styles.bundleContent}>
        <div className={styles.contentsContainer}>
          {expiredText && <div className={styles.courseSubtitle}>{expiredText}</div>}
          <h3 className={styles.coursesHeading}>Courses:</h3>
        </div>

        <div className={styles.courses}>
          {courses.map(course => {
            return <Course course={course} isComponentCourse key={course.id} />;
          })}
        </div>

        <div className={styles.contentsContainer}>
          {!!resources.length && (
            <div>
              <h3 className={styles.coursesHeading}>Resources:</h3>
              {resources.map(resource => {
                if (!resource.id || !resource.uri) {
                  return null;
                }

                return (
                  <div key={resource.id}>
                    <Link className={styles.courseTitle} href={resource.uri}>
                      {resource.title}
                    </Link>
                  </div>
                );
              })}
            </div>
          )}

          {progress === CourseProgressEnum.COMPLETED && group.hasCertificate && (
            <div className="mt-4">
              <DownloadCertificateButton courseOrProgramId={group.id} />
            </div>
          )}

          <GroupInfo group={group} progress={progress} />
        </div>
      </div>
    </div>
  );
};

export default CourseGroup;
