import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/solid";
import React from "react";
import Button from "components/common/Button";
import Link from "components/common/Link";
import { mapNodes } from "helpers/mapNodes";
import { MenuItem, useAssertMainMenuItemsContext } from "hooks/useMenuItemsContext";
import styles from "./ExploreMenu.module.css";

const MenuLinkItems = ({ menuItems }: { menuItems: MenuItem[] }) => {
  return (
    <>
      {menuItems?.map(menuItem =>
        menuItem.childItems?.edges?.length ? (
          <Menu key={menuItem.id}>
            {({ open }) => (
              <>
                <Menu.Button className={styles.menuItem}>
                  {menuItem.label} <ChevronRightIcon className="ml-4 h-4 w-4" aria-hidden="true" />
                </Menu.Button>
                <Transition
                  show={open}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                  className={styles.subMenu}
                >
                  <Menu.Items as="ul" key={menuItem.id} className={styles.subMenuItems}>
                    {mapNodes(menuItem.childItems, childItem => (
                      <Menu.Item
                        key={childItem.id}
                        className={styles.menuItem}
                        as={Link}
                        href={childItem.path || ""}
                      >
                        {childItem.label}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        ) : (
          <Menu.Item
            key={menuItem.id}
            className={styles.menuItem}
            as={Link}
            href={menuItem.path || ""}
          >
            {menuItem.label}
          </Menu.Item>
        )
      )}
    </>
  );
};

export default function ExploreMenu() {
  const menuItems = useAssertMainMenuItemsContext();
  return (
    <Menu as="div" className={styles.root}>
      {({ open }) => (
        <>
          <Menu.Button as={React.Fragment}>
            <Button theme="light">
              Explore <ChevronDownIcon className="ml-2 h-4 w-4" aria-hidden="true" />
            </Button>
          </Menu.Button>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Menu.Items as="ul" className={styles.mainMenuItems}>
              <MenuLinkItems menuItems={menuItems} />
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
