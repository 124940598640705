import { IS_SSR } from "@uplift-ltd/constants";
import Head from "next/head";
import React from "react";
import { PageMeta, pageMetaToSimplifiedPageMeta, usePageMetaContext } from "hooks/usePageMeta";
import logoImg from "images/logo.jpg";

type MetaProps = {
  initialMeta?: PageMeta | null;
};

export default function Meta({ initialMeta }: MetaProps) {
  const { pageMeta: contextPageMeta, pageTitle } = usePageMetaContext();

  // If we're rendering on the server, we'll need to provide pageMeta via initialMeta
  // because the context won't be updated yet. Once the client hydrates, it will
  // pick up any changes from the usePageMeta hook
  const pageMeta =
    IS_SSR && initialMeta ? pageMetaToSimplifiedPageMeta(initialMeta) : contextPageMeta;

  return (
    <Head>
      {/* <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" /> */}
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16.png" />
      {/* <link rel="manifest" href="/favicon/site.webmanifest" />
      <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#000000" /> */}
      <link rel="shortcut icon" href="/favicon.png" />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
      <meta name="theme-color" content="#000" />
      <link rel="alternate" type="application/rss+xml" href="/feed.xml" />
      {/* TODO: make this dynamically set through WordPress API, using static props for SEO */}
      <meta
        name="description"
        content="CE CEUs for Psychologists, LMFTs, Counselors, LPCs, Nurses and Social Workers | Zur Institute"
      />
      <meta
        property="og:image"
        content={pageMeta.metaImage || `https://${process.env.VERCEL_URL || ""}${logoImg.src}`}
      />
      <meta property="og:locale" content="en_US" />
      <title>{pageMeta.title || pageTitle}</title>
      <meta name="title" property="og:title" content={pageMeta.title || pageTitle} />
      <meta name="description" property="og:description" content={pageMeta.metaDesc || ""} />
      <meta name="keywords" property="og:keywords" content={pageMeta.metaKeywords || ""} />
      <meta property="og:type" content="article" />
      <link rel="canonical" href={pageMeta.canonical || ""} />
      <meta property="og:site_name" content="Zur Institute" />
      <meta property="og:url" content={pageMeta.canonical || ""} />
      <meta name="twitter:title" content={pageMeta.title || pageTitle} />
      <meta name="twitter:description" content={pageMeta.metaDesc || ""} />
      <meta name="twitter:url" content={pageMeta.canonical || ""} />
      <meta name="twitter:site" content={pageMeta.canonical || ""} />
      <meta name="twitter:creator" content="ZurInstitute" />
    </Head>
  );
}
