import cx from "classnames";
import React from "react";
import styles from "./Heading.module.css";

interface HeadingProps {
  className?: string;
  id?: string;
  tag: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

const Heading: React.FC<HeadingProps> = ({ children, className, tag: Tag, id }) => {
  return (
    <Tag className={cx(styles.root, styles[Tag], className)} id={id}>
      {children}
    </Tag>
  );
};

export default Heading;
