import cx from "classnames";
import React from "react";
import { FieldStatus } from "./types";
import styles from "./Form.module.css";

export interface HelpTextProps {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  status?: FieldStatus;
}

export function HelpText({ className, children, id, status }: HelpTextProps) {
  if (!children) {
    return null;
  }
  return (
    <p className={cx(styles.helpText, status && styles[`helpText-${status}`], className)} id={id}>
      {children}
    </p>
  );
}
