import { DatabaseIcon } from "@heroicons/react/outline";
import cx from "classnames";
import React from "react";
import Link from "components/common/Link";
import { MY_COURSES_URL, MY_WEBINARS_URL, ORDER_HISTORY_URL, PROFILE_URL } from "constants/urls";
import { useTypedAssertUserContext } from "hooks/useUserContext";
import styles from "./MyAccountSidebar.module.css";

enum MyAccountItemEnum {
  "profile" = "My Profile",
  "my-courses" = "My Courses",
  "my-webinars" = "My Webinars",
  "order-history" = "Order History",
}

type MyAccountItemKey = keyof typeof MyAccountItemEnum;

const MY_ACCOUNT_URLS: Record<MyAccountItemEnum, string> = {
  "My Profile": PROFILE_URL,
  "My Courses": MY_COURSES_URL,
  "My Webinars": MY_WEBINARS_URL,
  "Order History": ORDER_HISTORY_URL,
};

interface MyAccountItemProps {
  isActive: boolean;
  item: MyAccountItemKey;
}

const MyAccountItem: React.FC<MyAccountItemProps> = ({ children, isActive, item }) => (
  <li
    className={cx({
      [styles.isActive]: isActive,
    })}
  >
    <Link href={MY_ACCOUNT_URLS[MyAccountItemEnum[item]]}>{children}</Link>
  </li>
);

interface MyAccountSidebarProps {
  activeItem: MyAccountItemKey;
}

const MyAccountSidebar = ({ activeItem }: MyAccountSidebarProps) => {
  const currentUser = useTypedAssertUserContext();

  return (
    <div role="complementary">
      <div className={styles.wrapper}>
        <div className={styles.heading}>My Account</div>
        <ul className={styles.items}>
          <li className={styles.tokens}>
            <DatabaseIcon className="inline-flex w-4 h-4" /> {currentUser.credits} Tokens
          </li>
          {(
            Object.entries(MyAccountItemEnum) as ReadonlyArray<
              [MyAccountItemKey, MyAccountItemEnum]
            >
          ).map(([item, label]) => {
            return (
              <MyAccountItem isActive={item === activeItem} key={item} item={item}>
                {label}
              </MyAccountItem>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default MyAccountSidebar;
