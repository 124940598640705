// eslint-disable-next-line lodash/import-scope
import { CourseProgressEnum, ProductTypesEnum } from "__generated__/globalTypes";

interface BundledItem {
  id: string;
}

interface SplittableProduct<B> {
  id: string;
  type: ProductTypesEnum | null;
  bundledItems: B[];
}

export const getCourseProgressLabel = (course: {
  isStarted: boolean;
  courseProgress: CourseProgressEnum;
}) => {
  const { isStarted, courseProgress } = course;
  const isComplete = courseProgress === CourseProgressEnum.COMPLETED;

  if (!isStarted && !isComplete) return "pending";
  if (isStarted && !isComplete) return "progress";

  return "completed";
};

export const splitCoursesAndBundles = <P extends SplittableProduct<B>, B extends BundledItem>(
  products: P[]
) => {
  const courses: Record<string, P> = {};
  const bundledCourseIds = new Set<string>();
  const bundles: P[] = [];

  products.forEach(product => {
    if (product?.type === ProductTypesEnum.BUNDLE) {
      bundles.push(product);

      product.bundledItems.forEach(({ id: courseProductId }) => {
        const courseProduct = products.find(p => p.id === courseProductId);

        if (courseProduct) {
          courses[courseProduct.id] = courseProduct;
          bundledCourseIds.add(courseProduct.id);
        }
      });
    } else if (product.type === ProductTypesEnum.SIMPLE) {
      courses[product.id] = product;
    }
  });

  const individualCourses = Object.keys(courses)
    .filter(courseId => !bundledCourseIds.has(courseId))
    .map(courseId => courses[courseId]);

  return { bundles, individualCourses };
};
