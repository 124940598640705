import { gql, useEnhancedQuery } from "@uplift-ltd/apollo";
import React, { useEffect } from "react";
import Button, { ButtonLink } from "components/common/Button";
import {
  CertificateQueryQuery as CertificateQuery,
  CertificateQueryVariables,
} from "./__generated__/DownloadCertificateButton";

const CERTIFICATE_QUERY = gql`
  query CertificateQuery($id: ID!) {
    courseOrCourseGroup(id: $id) {
      ... on Course {
        id
        certificateLink
      }
      ... on CourseGroup {
        id
        certificateLink
      }
    }
  }
`;

interface DownloadCertificateButtonProps {
  courseOrProgramId: string;
}

const DownloadCertificateButton = ({ courseOrProgramId }: DownloadCertificateButtonProps) => {
  const { data, error, loading, refetch } = useEnhancedQuery<
    CertificateQuery,
    CertificateQueryVariables
  >(CERTIFICATE_QUERY, {
    variables: {
      id: courseOrProgramId,
    },
  });

  useEffect(() => {
    if (!loading && !error && !data?.courseOrCourseGroup?.certificateLink) {
      refetch();
    }
  }, [loading, data, error, refetch]);

  return (
    <div className="flex justify-center">
      {!loading && data?.courseOrCourseGroup?.certificateLink ? (
        <ButtonLink
          href={data.courseOrCourseGroup?.certificateLink}
          theme="primary"
          target="_blank"
        >
          Download your certificate
        </ButtonLink>
      ) : (
        <div className="text-center">
          <p>Please wait, this may take up to a minute...</p>
          <Button disabled theme="primary">
            Generating Certificate…
          </Button>
        </div>
      )}
    </div>
  );
};

export default DownloadCertificateButton;
