import { useEnhancedFormikContext } from "@uplift-ltd/formik";
import React from "react";
import Alert from "components/common/Alert";

interface FormStatusProps {
  className?: string;
  // Map of errror code to message
  errorCodeMap?: {
    [key: string]: string;
  };
}

const FormStatus: React.FC<FormStatusProps> = ({ className, errorCodeMap = {} }) => {
  const { status } = useEnhancedFormikContext();
  if (status) {
    if (status.formSuccess) {
      return (
        <Alert className={className} theme="success">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: status.formSuccess }} />
        </Alert>
      );
    }
    if (status.formError) {
      const errorOrCode =
        status.formError instanceof Error ? status.formError.message : status.formError;
      const errorMessage: string =
        errorOrCode in errorCodeMap ? errorCodeMap[errorOrCode] : errorOrCode;
      return (
        <Alert className={className} theme="danger">
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: errorMessage,
            }}
          />
        </Alert>
      );
    }
  }
  return null;
};

export { FormStatus };
