import { CourseProgressEnum } from "__generated__/globalTypes";

export const INITIAL_PROGRESS_STEP = CourseProgressEnum.OVERVIEW;

export const COURSE_PROGRESS_STEPS: [string, string, number?][] = [
  [CourseProgressEnum.OVERVIEW, "Overview"],
  [CourseProgressEnum.CONTENT, "Content"],
  [CourseProgressEnum.POSTTEST, "Posttest", 1],
  [CourseProgressEnum.OBJECTIVES_EVALUATION, "Objectives Evaluation", 2],
  [CourseProgressEnum.ACTIVITY_EVALUATION, "Activity Evaluation", 3],
  [CourseProgressEnum.COMPLETED, "Completed"],
];

export function getStepIndex(step: string) {
  return COURSE_PROGRESS_STEPS.findIndex(([s]) => s === step);
}

export const getQuizTitleAndNumber = (courseProgress: CourseProgressEnum) => {
  const index = getStepIndex(courseProgress);
  const quiz = COURSE_PROGRESS_STEPS[index];

  return { quizTitle: quiz[1], quizNumber: quiz[2] };
};

function isCourseProgressEnum(step?: string): step is CourseProgressEnum {
  return typeof step === "string" && step in CourseProgressEnum;
}

export function clampProgressStep(
  step?: string,
  maxStep?: CourseProgressEnum,
  isEnrolled?: boolean
): CourseProgressEnum {
  if (!isEnrolled) {
    return INITIAL_PROGRESS_STEP;
  }

  if (!isCourseProgressEnum(step)) {
    if (!isCourseProgressEnum(maxStep)) {
      return CourseProgressEnum.CONTENT;
    }

    return maxStep;
  }

  if (!isCourseProgressEnum(maxStep)) {
    return CourseProgressEnum.CONTENT;
  }

  const stepIndex = getStepIndex(step);
  const maxStepIndex = getStepIndex(maxStep);

  return stepIndex <= maxStepIndex ? step : maxStep;
}
