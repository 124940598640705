import { SearchIcon } from "@heroicons/react/solid";
import { makeUrl } from "@uplift-ltd/strings";
import React, { useCallback, useState } from "react";
import { Input } from "components/common/Form";
import { SEARCH_PAGE_URL } from "constants/urls";
import useRouterNavigation from "hooks/useRouterNavigation";
import styles from "./SearchBar.module.css";

const SearchBar = () => {
  const [query, setQuery] = useState("");
  const { push } = useRouterNavigation();

  const onSubmitSearch = useCallback(() => {
    push(makeUrl(SEARCH_PAGE_URL, undefined, { query }));
  }, [push, query]);

  return (
    <div className={styles.root}>
      <SearchIcon className={styles.searchIcon} onClick={onSubmitSearch} />
      <Input
        placeholder="Search…"
        className={styles.searchInput}
        onChange={e => setQuery(e.target.value)}
        onKeyDown={e => {
          if (e.key === "Enter") onSubmitSearch();
        }}
      />
    </div>
  );
};

export default SearchBar;
