/* eslint-disable jsx-a11y/label-has-associated-control */

import cx from "classnames";
import React from "react";
import styles from "./Form.module.css";

export type LabelProps = JSX.IntrinsicElements["label"] & {
  required?: boolean;
};

export function Label({ className, children, required, ...rest }: LabelProps) {
  return (
    <label className={cx(styles.label, className)} {...rest}>
      {children}
      {required && (
        <span className={styles.labelRequired} title="required">
          *
        </span>
      )}
    </label>
  );
}
