import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gql, useEnhancedQuery } from "@uplift-ltd/apollo";
import React from "react";
import { ButtonLink } from "components/common/Button";
import { NEWSLETTER_SIGNUP_URL } from "constants/site";
import useLocalStorage from "hooks/useLocalStorage";
import { GetNewsletterPopupQueryQuery as GetNewsletterPopupQuery } from "./__generated__/NewsletterPopup";
import styles from "./NewsletterPopup.module.css";

export const NEWSLETTER_POPUP_QUERY = gql`
  query GetNewsletterPopupQuery {
    newsletterPopup {
      newsletterPopupOptions {
        link
        linkText
        title
        description
        display
      }
    }
  }
`;

const NEWSLETTER_POPUP_DISMISSED = "Newsletter_Popup_Dismissed";

const NewsletterPopup = () => {
  const { data } = useEnhancedQuery<GetNewsletterPopupQuery>(NEWSLETTER_POPUP_QUERY);
  const { display, link, linkText, description, title } =
    data?.newsletterPopup?.newsletterPopupOptions || {};
  const [isDismissed, setIsDismissed] = useLocalStorage(NEWSLETTER_POPUP_DISMISSED, false);
  const setDismissed = () => {
    setIsDismissed(true);
  };
  if (!display || isDismissed) {
    return null;
  }

  return (
    <div className={styles.newsletterPopup}>
      <div className={styles.newsletterPopupContainer}>
        <div className={styles.newsletterPopupTitleContainer}>
          <p className={styles.newsletterPopupTitle}>{title}</p>
          <button type="button" onClick={setDismissed} className={styles.newsletterPopupCloseIcon}>
            <FontAwesomeIcon className={styles.socialIcon} icon={faWindowClose} />
          </button>
        </div>
        <div className={styles.newsletterPopupDescContainer}>
          <p className={styles.newsletterPopupDesc}>{description}</p>
        </div>
        <div className={styles.newsletterPopupCtaContainer}>
          <ButtonLink
            href={link || NEWSLETTER_SIGNUP_URL}
            target="_blank"
            className={styles.newsletterPopupCta}
            rel="noopener noreferrer"
          >
            {linkText}
          </ButtonLink>
        </div>
      </div>
    </div>
  );
};

export default NewsletterPopup;
