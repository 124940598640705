import cx from "classnames";
import React from "react";
import { FieldStatus } from "./types";
import styles from "./Form.module.css";

type HTMLInputProps = JSX.IntrinsicElements["input"];

export interface InputProps extends HTMLInputProps {
  status?: FieldStatus;
  value?: string;
}

export function Input({ className, type = "text", status, ...rest }: InputProps) {
  return (
    <input
      className={cx(styles.input, status && styles[`input-${status}`], className)}
      type={type}
      {...rest}
    />
  );
}
