import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAfterDelay } from "@uplift-ltd/use-after-delay";
import cx from "classnames";
import React from "react";
import styles from "./Loading.module.css";

const LINES_COUNT = 5;

// TODO: Maybe add inline/modal/overlay style later?
export type LoadingType = "center" | "full-page";

export type LoadingProps = {
  delayMs?: number;
  type?: LoadingType;
};

// TODO: maybe make this look like the logo leafs spinning instead
const Loading = ({ type = "center", delayMs = 200 }: LoadingProps) => {
  const pastDelay = useAfterDelay(delayMs);

  if (!pastDelay) return null;

  return (
    <div
      className={cx(styles.loaderContainer, {
        [styles.loadingContainerCenter]: type === "center",
        [styles.loadingContainerFullPage]: type === "full-page",
      })}
    >
      <div className={styles.loaderContent}>
        {Array.from({ length: LINES_COUNT }).map((_, i) => (
          <div key={`line-${i}`} className={styles.loaderLineWrapper}>
            <div className={styles.loaderLine} />
          </div>
        ))}
      </div>
    </div>
  );
};

// use a default 0ms delay so that we don't see the layout shrink and then pop out again
export const FullPageLoading = ({ delayMs = 0 }: Pick<LoadingProps, "delayMs">) => {
  return <Loading type="full-page" delayMs={delayMs} />;
};

export const LoadingButton = () => {
  return (
    <span className="opacity-80">
      <FontAwesomeIcon icon={faSpinner} spin className="mr-2" /> Processing...
    </span>
  );
};

export default Loading;
