import cx from "classnames";
import React from "react";
import { FieldStatus, OptionItem } from "./types";
import styles from "./Form.module.css";

type HTMLSelectOptionProps = JSX.IntrinsicElements["option"];

export function SelectOption(props: HTMLSelectOptionProps) {
  return <option {...props} />;
}

function defaultRenderItem<OptionValue extends string = string, OptionExtra = never>(
  option: OptionItem<OptionValue, OptionExtra>
) {
  return (
    <SelectOption key={option.value} value={option.value}>
      {option.label}
    </SelectOption>
  );
}

type HTMLSelectProps = JSX.IntrinsicElements["select"];

type EnhancedHTMLSelectElement<Value extends string = string> = Exclude<
  HTMLSelectElement,
  "value"
> & {
  value: Value;
};

export interface SelectProps<OptionValue extends string = string, OptionExtra = never>
  extends HTMLSelectProps {
  inputRef?: React.Ref<HTMLSelectElement>;
  onChange: React.ChangeEventHandler<EnhancedHTMLSelectElement<OptionValue>>;
  options: OptionItem<OptionValue, OptionExtra>[];
  renderItem?: (opt: OptionItem<OptionValue, OptionExtra>, idx: number) => JSX.Element;
  status?: FieldStatus;
  startEmpty?: boolean;
  value?: OptionValue;
}

export function Select<OptionValue extends string = string, OptionExtra = never>({
  className,
  placeholder,
  required,
  status,
  startEmpty,
  options,
  renderItem = defaultRenderItem,
  ...rest
}: SelectProps<OptionValue, OptionExtra>) {
  const renderedOptions = options.map(renderItem);

  let addEmptyOption = false;

  if (
    (!required || (startEmpty && !rest.value)) &&
    !renderedOptions.find(renderedOption => !renderedOption.props?.value)
  ) {
    addEmptyOption = true;
  }

  return (
    <select
      className={cx(styles.select, status && styles[`input-${status}`], className)}
      placeholder={placeholder}
      required={required}
      {...rest}
    >
      {addEmptyOption && <SelectOption value="">{placeholder}</SelectOption>}
      {options.map(renderItem)}
    </select>
  );
}
