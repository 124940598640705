/* eslint-disable jsx-a11y/anchor-is-valid */

import cx from "classnames";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { useRouter } from "next/router";
import React from "react";

export type LinkProps = NextLinkProps & JSX.IntrinsicElements["a"] & { activeClassName?: string };

const Link: React.FC<LinkProps> = ({
  children,
  href,
  as,
  replace,
  scroll,
  shallow,
  passHref,
  prefetch,
  locale,
  activeClassName,
  ...anchorProps
}) => {
  const { pathname } = useRouter();
  const isActive = pathname === href;
  return (
    <NextLink
      href={href}
      as={as || href}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref={passHref}
      prefetch={prefetch}
      locale={locale}
    >
      <a
        {...anchorProps}
        className={cx(anchorProps.className, isActive && activeClassName)}
        aria-current={isActive ? anchorProps["aria-current"] || "page" : undefined}
      >
        {children}
      </a>
    </NextLink>
  );
};

export default Link;
